<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Tambah Layanan Salon</h1>
    <div>
      <SalonServiceForm @submit="createSalonService" />
    </div>
  </div>
</template>

<script setup>
import {useSalonServiceForm} from "@/composables/salon_service";
import {useRouter} from "vue-router";
import SalonServiceForm from "./Form";

const {create} = useSalonServiceForm()
const router = useRouter();

const createSalonService = async (data) => {
  data.status = 1
  data.is_home_service = 0
  await create(data)
  await router.push({name: 'salon-services'})
}
</script>

<style scoped>

</style>